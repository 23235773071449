import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";

import moment from "moment";
import * as Yup from "yup";

import { Formik, Form, Field } from "formik";

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@material-ui/core";

import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const MessageSchema = Yup.object().shape({
  type: Yup.string().required("Obrigatório"),
  initialDate: Yup.date().required("Obrigatório"),
  finalDate: Yup.date().required("Obrigatório"),
});

const convertJsonToCsv = (data) => {
  if (!data.length) {
    return;
  }

  const headers = Object.keys(data[0]);

  const rows = data.map((row) =>
    headers
      .map((header) => `"${String(row[header]).replace(/"/g, '""')}"`)
      .join(";")
  );

  return ["\uFEFF".concat(headers.join(";")), ...rows].join("\n");
};

const Report = () => {
  const classes = useStyles();

  const types = [
    { id: "message-csv", label: "Mensagens (CSV)" },
    { id: "contact", label: "Contatos" },
  ];
  const initialState = {
    type: "message-csv",
    initialDate: moment().format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
  };

  const getReport = async (params) => {
    try {
      let data;

      if (params.type === "contact") {
        const response = await api.get("/contacts/report", { params });
        data = response.data.contacts.map((item) => ({
          nome: String(item.name),
          número: String(item.number),
          email: item.email ?? "",
          "código do criador do contato": item.createdBy ?? "",
          "nomde  do criador do contato": item.createdbyname ?? "",
          "código do atendente": item.sellerid ?? "",
          "nome do atendente": item.sellername ?? "",
          tags: item.tags ?? "",
          "código da conexão": item.whatsappId ?? "",
          "nome da conexão": item.whatsappName ?? "",
          "código da fila": item.queueId ?? "",
          "nome da fila": item.queueName ?? "",
        }));
      } else {
        const response = await api.get("/messages/report", { params });
        data = response.data?.map((item) => ({
          "código do atendimento": item["código do atendimento"],
          "status do atendimento": item["status do atendimento"] ?? "",
          mensagem: item.mensagem ?? "",
          lida: item.lida ?? "",
          "data cadastro": item["data cadastro"] ?? "",
          origem: item.origem ?? "",
          "código do cliente": item["código do cliente"] ?? "",
          "nome do cliente": item["nome do cliente"] ?? "",
          "whatsapp do cliente": item["whatsapp do cliente"] ?? "",
          "código do vendedor": item["código do vendedor"] ?? "",
          "nome do vendedor": item["nome do vendedor"] ?? "",
          "email do vendedor": item["email do vendedor"] ?? "",
          "código do whatsapp": item["código do whatsapp"] ?? "",
          "nome do whatsapp": item["nome do whatsapp"] ?? "",
          "código da fila": item["código da fila"] ?? "",
          "nome da fila": item["nome da fila"] ?? "",
          tags: item.tags ?? "",
        }));
      }

      if (!data?.length) {
        toast.warn("Nenhum dado encontrado para os filtros informados.");
        return;
      }

      const link = document.createElement("a");

      link.href = URL.createObjectURL(
        new Blob([convertJsonToCsv(data)], { type: "text/csv;charset=utf-8;" })
      );

      link.download = `report_${new Date().getTime()}.csv`;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      toastError("Falha ao gerar o relatório.");
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <Title>{i18n.t("reports.title")}</Title>
      </MainHeader>
      <Paper>
        <Formik
          initialValues={initialState}
          enableReinitialize={true}
          validationSchema={MessageSchema}
          onSubmit={(values, actions) => {
            getReport(values);
          }}
        >
          {({ touched, errors, values }) => (
            <Form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  marginBottom: "16px",
                }}
              >
                <Field
                  as={Select}
                  name="type"
                  label="Tipo"
                  error={touched.type && Boolean(errors.type)}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  as={TextField}
                  label={i18n.t("Data Inicial")}
                  type="date"
                  name="initialDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={touched.initialDate && Boolean(errors.initialDate)}
                  helperText={touched.initialDate && errors.initialDate}
                  variant="outlined"
                  fullWidth
                />
                <Field
                  as={TextField}
                  label={i18n.t("Data Final")}
                  type="date"
                  name="finalDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={touched.finalDate && Boolean(errors.finalDate)}
                  helperText={touched.finalDate && errors.finalDate}
                  variant="outlined"
                  fullWidth
                />
                <Button
                  type="submit"
                  style={{ minWidth: "10vw" }}
                  variant="contained"
                  color="success"
                >
                  Exportar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </MainContainer>
  );
};

export default Report;
