import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, Grid, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AttachFile from "@material-ui/icons/AttachFile";
import { head } from "lodash";
import ConfirmationModal from "../ConfirmationModal";
import MessageVariablesPicker from "../MessageVariablesPicker";
import CheckboxField from "../FormFields/CheckboxField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  whatsappId,
  contactId,
  cleanContact,
  reload,
  isEditing,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
    notifyMe: false,
    notifyBefore: 15,
    notifyBeforeText: "",
    notifyNowText: "",
    justNotifyMe: false,
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const messageInputRef = useRef();
  const notifyBeforeTextRef = useRef();
  const notifyNowTextRef = useRef();

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.filter((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId: companyId },
          });
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId: [contactId] };
            });
          }

          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);

          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
            };
          });
          setCurrentContact([data.contact]);
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [scheduleId, contactId, open, user]);

  useEffect(() => {
    if (!scheduleId) {
      const day = new Date().getDate();
      const year = new Date().getFullYear();
      const month = new Date().getMonth();

      const minute = new Date().getMinutes();
      const hour = new Date().getHours();

      const _date = `${year}-${month + 1}-${day}T${user.scheduleSendAt ?? `${hour}:${minute}`}`;

      setSchedule({
        ...schedule,
        notifyBefore: user.scheduleNotifyBefore,
        notifyBeforeText: user.scheduleNotifyBeforeText,
        notifyNowText: user.scheduleNotifyNowText,
        sendAt:  moment(_date).format("YYYY-MM-DDTHH:mm"),
      });
    }
  }, []);

  const handleClose = () => {
    onClose();
    setAttachment(null);
    setSchedule(initialState);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id, whatsappId };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
          history.push("/schedules");
        }
      }
    } catch (err) {
      toastError(err);
    }
    setCurrentContact([]);
    setSchedule(initialState);
    handleClose();
  };

  const handleClickMsgVar = async (msgVar, setValueFunc, ref, field) => {
    const el = ref.current;
    const firstHalfText = el.value.substring(0, el.selectionStart);
    const secondHalfText = el.value.substring(el.selectionEnd);
    const newCursorPos = el.selectionStart + msgVar.length;

    setValueFunc(field, `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise((r) => setTimeout(r, 100));
    el.setSelectionRange(newCursorPos, newCursorPos);
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule((prev) => ({
        ...prev,
        mediaPath: null,
      }));
      toast.success(i18n.t("scheduleModal.toasts.deleted"));
      if (typeof reload == "function") {
        reload();
      }
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={isEditing ? "xs" : "lg"}
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {schedule.status === "ERRO"
            ? "Erro de Envio"
            : `Mensagem ${capitalize(schedule.status)}`}
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            ref={attachmentFile}
            onChange={(e) => handleAttachmentFile(e)}
          />
        </div>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            setFieldValue,
            isValid,
          }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                      fullWidth
                      multiple={true}
                      options={contacts}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Contato"
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                      value={currentContact}
                      onChange={(e, contacts) => {
                        const contactId = contacts?.length
                          ? contacts.map((item) => item.id)
                          : [];
                        setSchedule({ ...values, contactId });
                        setCurrentContact(contacts?.length ? contacts : []);
                      }}
                    ></Autocomplete>
                  </FormControl>
                </div>
                <Field
                  as={CheckboxField}
                  label={i18n.t("Notificar-me?")}
                  name="notifyMe"
                  variant="outlined"
                  margin="dense"
                />
                <br />
                <Grid
                  container
                  spacing={isEditing ? 0 : 2}
                  sx={{ display: { xs: "block", md: "flex" } }}
                >
                  <Grid item xs={12} md={isEditing ? 12 : 4}>
                    <Field
                      as={TextField}
                      rows={9}
                      multiline={true}
                      label={i18n.t("scheduleModal.form.body")}
                      name="body"
                      inputRef={messageInputRef}
                      error={touched.body && Boolean(errors.body)}
                      helperText={touched.body && errors.body}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      required
                    />

                    <MessageVariablesPicker
                      disabled={isSubmitting}
                      onClick={(value) =>
                        handleClickMsgVar(
                          value,
                          setFieldValue,
                          messageInputRef,
                          "body"
                        )
                      }
                    />
                  </Grid>
                  {!isEditing && (
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        rows={9}
                        multiline={true}
                        label={i18n.t("Mensagem de aviso")}
                        name="notifyBeforeText"
                        inputRef={notifyBeforeTextRef}
                        error={
                          touched.notifyBeforeText &&
                          Boolean(errors.notifyBeforeText)
                        }
                        helperText={
                          touched.notifyBeforeText && errors.notifyBeforeText
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />

                      <MessageVariablesPicker
                        disabled={isSubmitting}
                        onClick={(value) =>
                          handleClickMsgVar(
                            value,
                            setFieldValue,
                            notifyBeforeTextRef,
                            "notifyBeforeText"
                          )
                        }
                      />
                    </Grid>
                  )}

                  {!isEditing && (
                    <Grid item xs={12} md={4}>
                      <Field
                        as={TextField}
                        rows={9}
                        multiline={true}
                        label={i18n.t("Mensagem imediata")}
                        name="notifyNowText"
                        inputRef={notifyNowTextRef}
                        error={
                          touched.notifyNowText && Boolean(errors.notifyNowText)
                        }
                        helperText={
                          touched.notifyNowText && errors.notifyNowText
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />

                      <MessageVariablesPicker
                        disabled={isSubmitting}
                        onClick={(value) =>
                          handleClickMsgVar(
                            value,
                            setFieldValue,
                            notifyNowTextRef,
                            "notifyNowText"
                          )
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <br />
                <Grid
                  container
                  spacing={isEditing ? 0 : 2}
                  sx={{ display: { xs: "block", md: "flex" } }}
                >
                  <Grid item xs={12} md={isEditing ? 12 : 6}>
                    <Field
                      as={TextField}
                      label={i18n.t("scheduleModal.form.sendAt")}
                      type="datetime-local"
                      name="sendAt"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  {!isEditing && (
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t(
                          "Tempo para mensagem de aviso em minutos"
                        )}
                        type="number"
                        name="notifyBefore"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          touched.notifyBefore && Boolean(errors.notifyBefore)
                        }
                        helperText={touched.notifyBefore && errors.notifyBefore}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>

                {(schedule.mediaPath || attachment) && (
                  <Grid xs={12} item>
                    <Button startIcon={<AttachFile />}>
                      {attachment ? attachment.name : schedule.mediaName}
                    </Button>
                    <IconButton
                      onClick={() => setConfirmationOpen(true)}
                      color="secondary"
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <Button
                      color="secondary"
                      onClick={() =>
                        handleSaveSchedule({ ...values, justNotifyMe: true })
                      }
                      disabled={isSubmitting}
                      variant="outlined"
                      fullWidth
                    >
                      Apenas notificar-me
                    </Button>
                  </Grid>
                  {!attachment && !schedule.mediaPath && (
                    <Grid item md={3} xs={12}>
                      <Button
                        color="primary"
                        onClick={() => attachmentFile.current.click()}
                        disabled={isSubmitting}
                        variant="outlined"
                        fullWidth
                      >
                        {i18n.t("quickMessages.buttons.attach")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item md={3} xs={12}>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                      fullWidth
                    >
                      {i18n.t("scheduleModal.buttons.cancel")}
                    </Button>
                  </Grid>
                  {(schedule.sentAt === null || schedule.sentAt === "") && (
                    <Grid item md={3} xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid || !touched}
                        variant="contained"
                        className={classes.btnWrapper}
                        fullWidth
                      >
                        {scheduleId
                          ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                          : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
